@font-face {
  font-family: system;
  font-weight: 300;
  font-style: normal;
  src: local('Avenir-Light'), local('.SFNSText-Light'), local('.HelveticaNeueDeskInterface-Light'),
    local('.LucidaGrandeUI'), local('Segoe UI Light'), local('Ubuntu Light'), local('Roboto-Light'), local('DroidSans'),
    local('Tahoma');
}

@font-face {
  font-family: system;
  font-weight: normal;
  font-style: normal;
  src: local('AvenirNext-Regular'), local('.SFNSText-Regular'), local('.HelveticaNeueDeskInterface-Regular'),
    local('.LucidaGrandeUI'), local('Segoe UI'), local('Ubuntu'), local('Roboto-Regular'), local('DroidSans'),
    local('Tahoma');
}

@font-face {
  font-family: system;
  font-weight: 500;
  font-style: normal;
  src: local('AvenirNext-Medium'), local('.SFNSText-Medium'), local('.HelveticaNeueDeskInterface-MediumP4'),
    local('.LucidaGrandeUI'), local('Segoe UI Semibold'), local('Ubuntu Medium'), local('Roboto-Medium'),
    local('DroidSans-Bold'), local('Tahoma Bold');
}

@font-face {
  font-family: system;
  font-weight: bold;
  font-style: normal;
  src: local('AvenirNext-DemiBold'), local('.SFNSText-Bold'), local('.HelveticaNeueDeskInterface-Bold'),
    local('.LucidaGrandeUI'), local('Segoe UI Bold'), local('Ubuntu Bold'), local('Roboto-Bold'),
    local('DroidSans-Bold'), local('Tahoma Bold');
}

body {
  background: #fff;
  margin: 0;
  font-family: system, 'Helvetica Neue', 'Helvetica', -apple-system, 'Roboto', 'Segoe UI', BlinkMacSystemFont, 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus-visible {
  outline: 3px solid #058cff;
}

.screen-reader-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
